window.addEventListener('DOMContentLoaded', (event) => {
    let addThrone = false;

    $("#singIn, #singInMobile").on("click", function () {
        addThrone = false;
        $('#login-modal').modal('show');
    });

    $("#loginModal").on("submit", function (event) {
        event.preventDefault();
        const username = $("#inputEmail").val();
        const password = $("#inputPassword").val();
        const csrfToken = $("#csrfToken").val();
        const errorInfo = $("#errorInfo");
        errorInfo.hide();
        errorInfo.html('');
        $.ajax({
            url: '/login',
            type: 'POST',
            data: {
                '_username': username,
                '_password': password,
                '_csrf_token': csrfToken
            },
            dataType: 'json',
            complete: function (response) {
                const  res = response.responseJSON;

                if (true === res.login) {
                    if (true === addThrone) {
                        location.replace('/dodajtron');
                    } else {
                        window.location.reload();
                    }
                } else {
                    errorInfo.html(res.error);
                    errorInfo.show();
                }
            }
        });
    })

    $("#add-throne-circle-button, #add-throne-button-info, #add-throne-button-menu, #add-throne-button-nav").on("click", function () {
        $.ajax({
            url: '/check-is-logged',
            type: 'GET',
            dataType: 'json',
            complete: function (response) {
                const  res = response.responseJSON;
                if (res.isLogged) {
                    location.replace('/dodajtron');
                } else {
                    addThrone = true;
                    $('#login-modal').modal('show');
                }
            }
        });
    });

});
