import { initMap } from "./maps";
import $ from "jquery";

$(document).ready( function () {
    if ($("#maps").length) {
        resizeMapWindow();
        initMap(52.237049, 20.017532, 'maps');

        function resizeMapWindow() {
            const hWindow = $(window).height();
            const hMenu = $('#navigation').height();
            const hFooter = $('#footer').height();
            const newHeightMaps = hWindow - hMenu - hFooter;
            $("#maps").height(newHeightMaps);
        }

        $("#maps").resize(function () {
            resizeMapWindow();
        });
    }
});