$(document).ready(function () {
    $("#throne_form_mon_from").on("keyup", function (e) {
        $("#throne_form_tue_from").val($(this).val());
        $("#throne_form_wed_from").val($(this).val());
        $("#throne_form_thu_from").val($(this).val());
        $("#throne_form_fri_from").val($(this).val());
        $("#throne_form_sat_from").val($(this).val());
        $("#throne_form_sun_from").val($(this).val());
    });
    $("#throne_form_mon_to").on("keyup", function (e) {
        $("#throne_form_tue_to").val($(this).val());
        $("#throne_form_wed_to").val($(this).val());
        $("#throne_form_thu_to").val($(this).val());
        $("#throne_form_fri_to").val($(this).val());
        $("#throne_form_sat_to").val($(this).val());
        $("#throne_form_sun_to").val($(this).val());
    });

    $("#throne_form_tue_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_tue_from").val($("#throne_form_mon_from").val());
            $("#throne_form_tue_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_tue_from").val('');
            $("#throne_form_tue_to").val('');
        }
    });

    $("#throne_form_wed_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_wed_from").val($("#throne_form_mon_from").val());
            $("#throne_form_wed_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_wed_from").val('');
            $("#throne_form_wed_to").val('');
        }
    });

    $("#throne_form_thu_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_thu_from").val($("#throne_form_mon_from").val());
            $("#throne_form_thu_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_thu_from").val('');
            $("#throne_form_thu_to").val('');
        }
    });

    $("#throne_form_fri_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_fri_from").val($("#throne_form_mon_from").val());
            $("#throne_form_fri_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_fri_from").val('');
            $("#throne_form_fri_to").val('');
        }
    });

    $("#throne_form_sat_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_sat_from").val($("#throne_form_mon_from").val());
            $("#throne_form_sat_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_sat_from").val('');
            $("#throne_form_sat_to").val('');
        }
    });

    $("#throne_form_sun_open").on("change", function (e) {
        if($(this).is(':checked')) {
            $("#throne_form_sun_from").val($("#throne_form_mon_from").val());
            $("#throne_form_sun_to").val($("#throne_form_mon_to").val());
        } else {
            $("#throne_form_sun_from").val('');
            $("#throne_form_sun_to").val('');
        }
    });
})
