import './validator/initialisation';
import t from "mdb-ui-kit/src/js/mdb/dom/manipulator";

// export function getRecaptcha() {
// 	let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
// 	let params = new URLSearchParams(url.search);
// 	return grecaptcha.execute(params.get('render'));
// };

$.fn.ajaxShowFieldError = function(options) {
	return this.each(function() {
		const t = $(this);
		t.addClass('has-invalid-feedback-ajax').on('change.ajaxFieldError', ajaxRemoveFieldError);
		t.parent().append(`<div class='invalid-feedback invalid-feedback-ajax d-block'>${options.error}</div>`)

	});
};

function ajaxRemoveFieldError(){
	var t = $(this);
	t.parent().find( '.invalid-feedback-ajax' ).remove();
	t.removeClass('has-invalid-feedback-ajax').off('change.ajaxFieldError');
}

/**
 * Should be called as form handler
 */
function ajaxRemoveFormFieldsErrors(){
	var t = $(this);
	t.find('.invalid-feedback-ajax').remove();
	t.find('.has-invalid-feedback-ajax').off('change.ajaxFieldError');
	t.off('change.ajaxFormError')
}


$.fn.toggleValid = function (valid) {
    this.each((i, element) => element.setCustomValidity(valid ? "" : 'invalid'));
};

export function processFormErrors(errors, form) {
	// console.log(errors);
	for (let fieldName in errors) {
		if (fieldName == form.name) {
			for (let error of errors[fieldName]) {
				alert(error);
			}
		} else {
			let error = errors[fieldName] + '<br>';
            var element = null;
            if (form.name.trim()) {
                element = $(form).find('[name="' + form.name.trim() + '[' + fieldName + ']"]')
            } else {
                element = $(form).find('[name="' + fieldName + '"]')
            }
            console.log(form, form.name.trim(), fieldName, '[name="' + fieldName + '"]')
			if( element.length ){
				element.ajaxShowFieldError({error: error});
			}else{
				console.log( '[name="' + form.name + '[' + fieldName + ']"]' );
				console.log( form.name );
				console.log( fieldName );
				$(form).prepend( `<div class='invalid-feedback invalid-feedback-ajax d-block'>${error}</div>` );
			}

		}
	}
};

// export async function setRecaptchaResponse(form) {
//     if ($(form).data('recaptcha') && !$(form.elements).filter('.g-recaptcha-response').length) {
// 		let fieldName = typeof $(form).data('recaptcha') == 'string' ? $(form).data('recaptcha') : 'g-recaptcha-response';
//         $(form).append(`<input type="hidden" name="${fieldName}" class="g-recaptcha-response">`);
//     }
//     if ($(form.elements).filter('.g-recaptcha-response').length) {
//         $(form.elements).filter('.g-recaptcha-response').val(await getRecaptcha());
//     }
// };

$('.ajax-form').on('submit', async function(event) {
	event.preventDefault();
	$(this.elements).filter('[type=submit]').prop('disabled', true);
	//await setRecaptchaResponse(this);

	try {
		var data = await $.ajax({
			type: 'post',
			url: this.action,
			data: new FormData(this),
			processData: false,
			contentType: false,
		});
	} catch (xhr) {
		let t = $(this);
		let errorEvent = $.Event('submit-error');
		t.trigger(errorEvent, xhr);
		if (!errorEvent.isDefaultPrevented()) {
			if (xhr.responseJSON && xhr.responseJSON.errors) {
				processFormErrors(xhr.responseJSON.errors, this);
			} else {
				//TODO - jakiś modal albo co....

                if (xhr.responseJSON.hasOwnProperty('message')) {
                    alert(xhr.responseJSON.message);
                } else {
                    alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
                }


			}
		}
		t.on('submit.ajaxFieldError', ajaxRemoveFormFieldsErrors);

		let firsterror = $(this).find('.has-invalid-feedback-ajax:first');
		if( firsterror.length ){
			$('html, body').animate({
				scrollTop: firsterror.offset().top - 96
			}, 0);
		}


		return;
	} finally {
		$(this.elements).filter('[type=submit]').prop('disabled', false);
	}

	let successEvent = $.Event('submit-success');
	$(this).trigger(successEvent, data);
	if (!successEvent.isDefaultPrevented() && !$(this).hasClass('filter-form')) {
		this.reset();
		$(this).removeClass('was-validated');
		//$(this).find('.has-invalid-feedback-ajax').ajaxInvalidFeedback('destroy');
		//$(this).find('.is-valid').removeClass('is-valid');
		//$(this).find('.is-invalid').removeClass('is-invalid');
		$(this).find('.form-file .btn-remove').hide();
		$(this).find('img[data-src]').each(function() {
			$(this).attr('src', $(this).data('src'));
		});
		if (data.redirect) location.href = data.redirect;
	};
});

$('#register-button').on('click', function () {
    $('.register-options').addClass('d-none');
    $('#register-form-container').addClass('show');
});

$('.switcher').on('click', function () {

    console.log($(this).find('input').is(':checked'));

    if ($(this).find('input').is(':checked')) {
        $(this).find('label').addClass('checked');
    } else {
        $(this).find('label').removeClass('checked');
        $(this).parent('div').find('.day-closed').css('marginLeft', '-25px');
    }
});

const throneFormType = $('input[name="throne_form[type]"]').val();

if (throneFormType === 'owner') {
    $("#throne_verify_1").show();
    $("#throne_verify_2").show();
} else {
    $("#throne_verify_1").hide();
    $("#throne_verify_2").hide();
}

$('input[name="throne_form[type]"]').on('change', function () {
    if ($(this).val() === 'owner') {
        $('.for-owner').removeClass('d-none');
        $('.owner-info').removeClass('d-none');
        $('.user-info').addClass('d-none');
        $("#throne_verify_1").show();
        $("#throne_verify_2").show();
    } else {
        $('.for-owner').addClass('d-none');
        $('.owner-info').addClass('d-none');
        $('.user-info').removeClass('d-none');
        $("#throne_verify_1").hide();
        $("#throne_verify_2").hide();
    }
});

$('#throne_form_hours_misc').on('change', function () {
    if ($(this).is(':checked')) {
        $('#open-days').attr('disabled', false);

        if ($('#throne_form_hours_24h').is(':checked')) {
            $('#throne_form_hours_24h').prop('checked', false);
            $('#throne_form_hours_24h').closest('.switcher').find('label').removeClass('checked');
        }
    } else {
        $('#open-days').attr('disabled', true);
    }
});

$('#throne_form_hours_24h').on('change', function () {
    if ($(this).is(':checked')) {
        $('#open-days').attr('disabled', true);

        if ($('#throne_form_hours_misc').is(':checked')) {
            $('#throne_form_hours_misc').prop('checked', false);
            $('#throne_form_hours_misc').closest('.switcher').find('label').removeClass('checked');
        }
    } else if($('#throne_form_hours_misc').is(':checked')) {
        $('#open-days').attr('disabled', false);
    }
});

$('#throne_form_openPeriodic').on('change', function () {
    if ($(this).is(':checked')) {
        $('fieldset.open-periodic').attr('disabled', false);
    } else {
        $('fieldset.open-periodic').attr('disabled', true);
    }
});

$('.day-time-group input[type="checkbox"]').on('change', function () {
    if ($(this).is(':checked')) {
        $(this).closest('.day-time-group').find('input').each(function () {
            $(this).attr('required', true).removeClass('d-none');
        });
        $(this).closest('.day-time-group').find('.day-closed').addClass('d-none');
        $(this).closest('.day-time-group').find('.day-inputs').removeClass('d-none');
    } else {
        $(this).closest('.day-time-group').find('input').each(function () {
            $(this).attr('required', false).addClass('d-none');
        });
        $(this).closest('.day-time-group').find('.day-closed').removeClass('d-none');
        $(this).closest('.day-time-group').find('.day-inputs').addClass('d-none');

        $($(this).closest('.day-time-group').find('.day-inputs').find('input')[0]).val('');
        $($(this).closest('.day-time-group').find('.day-inputs').find('input')[1]).val('');
    }
});

$('.custom-throne-image-field').on('change', function (e) {
    const parent = $(this).closest('.throne-image');
    const file = e.target.files[0];

    const size = file.size / 1000 /1000;

    if (size <= 5) {
        $('.throne-image-error').removeClass('d-block');
        if (file) {

            if (parent.find('label').css('background-image') !== 'none' && $('#images-to-delete').length > 0) {
                const imagesToDelete = $('#images-to-delete').val();
                const currentFile = parent.find('label').attr('data-image').replace('/upload/', '');
                $('#images-to-delete').val(imagesToDelete + `${currentFile},`)
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                parent.find('label').addClass('uploaded');
                parent.find('label').css({
                    'background-image': `url(${reader.result})`,
                    'background-position': 'center',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat'
                });

                $('.added-images').text($('label.uploaded').length);
            }
        }
    } else {
        e.preventDefault();
        e.stopPropagation();

        $(this).val(null);

        $('.throne-image-error').html('Maksymalna waga zdjęcia to 5MB');
        $('.throne-image-error').addClass('d-block');
    }

});

$('#throne_form_name, #throne_form_description, #throne_opinion_form_content, #throne_issue_form_content').keyup(function () {
    $(this).closest('.form-group').find('.counter').text($(this).val().length);
});

$('#user_registration_form_password').keyup(function () {
    const val = $(this).val();
    const hasUppercase = /[A-Z]+/.test(val);
    const hasLength = val.length >= 8;
    const hasSpecialChar = /[^\w\s]/gi.test(val);
    const passwordWidget = $('.password-widget');

    if (hasUppercase) {
        passwordWidget.find('.pass-uppercase .minus').addClass('d-none');
        passwordWidget.find('.pass-uppercase .condition-fulfilled').addClass('accepted');
    } else {
        passwordWidget.find('.pass-uppercase .minus').removeClass('d-none');
        passwordWidget.find('.pass-uppercase .condition-fulfilled').removeClass('accepted');
    }

    if (hasLength) {
        passwordWidget.find('.pass-length .minus').addClass('d-none');
        passwordWidget.find('.pass-length .condition-fulfilled').addClass('accepted');
    } else {
        passwordWidget.find('.pass-length .minus').removeClass('d-none');
        passwordWidget.find('.pass-length .condition-fulfilled').removeClass('accepted');
    }

    if (hasSpecialChar) {
        passwordWidget.find('.special-char .minus').addClass('d-none');
        passwordWidget.find('.special-char .condition-fulfilled').addClass('accepted');
    } else {
        passwordWidget.find('.special-char .minus').removeClass('d-none');
        passwordWidget.find('.special-char .condition-fulfilled').removeClass('accepted');
    }
});

$('#throne_gus').on('click', function (e) {
    e.preventDefault();
    const self = this;

    $(this).prop('disabled', true);

    $.ajax({
        url: `/gus/${$('#throne_form_nip').val()}`,
        type: 'GET',
        data: { '_token': $('#throne_form_nip').attr('data-token') },
        complete: function (response) {
            const gusData = response.responseJSON;

            $(self).prop('disabled', false);

            if (gusData.status === 'success') {
                $('#throne_form_nip').closest('div').find('.invalid-feedback-style').text('');
                $('#throne_form_nip').closest('div').find('.invalid-feedback-style').removeClass('d-block');
                const local = gusData.local ? `/${gusData.local}` : '';
                $('.company-data').addClass('show');
                $('.company-data').find('.company-name strong').text(gusData.companyName);
                $('.company-data').find('.company-address-1').text(`${gusData.street} ${gusData.house}${local}`);
                $('.company-data').find('.company-address-2').text(`${gusData.postal}, ${gusData.city}`);

                $('#throne_form_company_name').val(gusData.companyName);
                $('#throne_form_company_street').val(gusData.street);
                $('#throne_form_company_house').val(gusData.house);
                $('#throne_form_company_local').val(gusData.local);
                $('#throne_form_company_postal').val(gusData.postal);
                $('#throne_form_company_city').val(gusData.city);
            } else {
                $('.company-data').removeClass('show');
                $('#throne_form_nip').closest('div').find('.invalid-feedback-style').text(gusData.message);
                $('#throne_form_nip').closest('div').find('.invalid-feedback-style').addClass('d-block');
                $('#throne_form_company_name').val('');
                $('#throne_form_company_street').val('');
                $('#throne_form_company_house').val('');
                $('#throne_form_company_local').val('');
                $('#throne_form_company_postal').val('');
                $('#throne_form_company_city').val('');
            }
        }
    });
});

$('#throne_shipping_address').on('change', function () {
    if ($(this).is(':checked')) {
        $('#shipping-company-address').prop('disabled', false);
    } else {
        $('#shipping-company-address').prop('disabled', true);
    }
});

$('#clone_throne_address').on('change', function () {
    if ($(this).is(':checked')) {
        $('#throne_form_shipping_street').val($('#throne_form_street').val());
        $('#throne_form_shipping_city').val($('#throne_form_city').val());
        $('#throne_form_shipping_postal').val($('#throne_form_postal').val());
        $('#throne_form_shipping_house').val($('#throne_form_house').val());
        $('#throne_form_shipping_local').val($('#throne_form_local').val());
    } else {
        $('#throne_form_shipping_street').val('');
        $('#throne_form_shipping_city').val('');
        $('#throne_form_shipping_postal').val('');
        $('#throne_form_shipping_house').val('');
        $('#throne_form_shipping_local').val('');
    }
});

$('#throne-opinion-form').on('submit-success', function (e, data) {
    $('#throne-opinion-form').addClass('d-none');
    $('#throne-opinion-modal').find('h2').text('Dziękujemy za dodanie opinii, pojawi się po akceptacji moderatora');
    $('#add-opinion-button').addClass('d-none');
    $('#close-opinion-modal-button').removeClass('d-none');
    $('.throne-issue-thanks').removeClass('d-none');
});

$('#throne-wrong-information-form').on('submit-success', function (e, data) {
    $('#throne-wrong-information-form').addClass('d-none');
    $('#throne-wrong-information-modal').find('h2').text('Zgłoszenie przyjęte');
    $('#add-wrong-information-button').addClass('d-none');
    $('#close-wrong-information-modal-button').removeClass('d-none');
    $('.throne-wrong-information-thanks').removeClass('d-none');
});

$('#throne-issue-form').on('submit-success', function (e, data) {
    $('#throne-issue-form').addClass('d-none');
    $('#throne-issue-modal').find('h2').text('Zgłoszenie przyjęte');
    $('#add-issue-button').addClass('d-none');
    $('#close-issue-modal-button').removeClass('d-none');
    $('.throne-issue-thanks').removeClass('d-none');
    $('.take-over-throne-button').remove();
});

$('#throne-take-over-form').on('submit-success', function (e, data) {
    $('#throne-take-over-form').addClass('d-none');
    $('#take-over-throne-modal').find('h2').text('Przejęcie w trakcie');
    $('#take-over-button').addClass('d-none');
    $('#close-throne-take-over-modal-button').removeClass('d-none');
    $('.throne-take-over-thanks').removeClass('d-none');
    $('#take-over-throne-modal .take-over-throne-info-text').addClass('d-none');
    $('#take-over-throne-modal .throne-details-header').addClass('d-none');
    $('#take-over-throne-modal .add-another-throne').removeClass('d-none');
});
