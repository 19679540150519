import { initMap, map } from "./maps";
import $ from "jquery";

let ms;
let markers = new L.MarkerClusterGroup();
let markersList = [];

$(document).ready(function () {
    if ($('#mapTron').length) {
        initMap(52.237049, 20.017532, 'mapTron', 5, 25, false, false, false);
        $("#throne_form_city, #throne_form_street, #throne_form_house").on('focusout', function () {
            const throne_localize = $("#throne_localize");
            const street = $("#throne_form_street").val();
            const house = $("#throne_form_house").val();
            const local = $("#throne_form_local").val();
            const city = $("#throne_form_city").val();
            const mapLoad = $("#mapLoad");
            const throne_form_marker_lat = $("#throne_form_marker_lat");
            const throne_form_marker_lng = $("#throne_form_marker_lng");

            mapLoad.show();

            if (city === '') {
                alert('Proszę wprowadzić miasto')
            } else {
                let addr = '';
                if ('' !== street) {
                    addr += street;
                }
                if ('' !== house) {
                    addr += ' ' + house;
                }
                if ('' !== city) {
                    if (addr !== '') {
                        addr += ' ';
                    }
                    addr += city;
                }

                throne_localize.prop('disabled', true);

                $.ajax({
                    url: '/throne/geocode',
                    dataType: 'json',
                    data: {addr: addr},
                    type: 'GET',
                    success: function (response) {
                        markers.clearLayers();
                        ms = new L.Marker([response[1], response[0]], {draggable:'true'});
                        throne_form_marker_lat.val(response[1]);
                        throne_form_marker_lng.val(response[0]);
                        ms.on('dragend', function(event){
                            const marker = event.target;
                            const position = marker.getLatLng();
                            throne_form_marker_lat.val(position.lat);
                            throne_form_marker_lng.val(position.lng);
                        });
                        markersList.push(ms);
                        markers.addLayer(ms);
                        map.addLayer(markers);
                        map.setView(new L.LatLng(response[1], response[0]), 16);
                        throne_localize.prop('disabled', false);
                        mapLoad.hide();
                    },
                    error: function (jqXHR, exception) {
                        alert('Wystąpił błąd, spróbuj ponownie');
                        throne_localize.prop('disabled', false);
                        mapLoad.hide();
                    }
                });
            }
        });

        $('body').on('show-map', function () {
            const mapLoad = $("#mapLoad");
            map.remove();
            mapLoad.show();
            setTimeout( function () {
                initMap(52.237049, 20.017532, 'mapTron', 5, 25, false, false, false);
                const throne_localize = $("#throne_localize");
                const throne_form_marker_lat = $("#throne_form_marker_lat");
                const throne_form_marker_lat_val = throne_form_marker_lat.val();
                const throne_form_marker_lng = $("#throne_form_marker_lng");
                const throne_form_marker_lng_val = throne_form_marker_lng.val();
                markers.clearLayers();
                ms = new L.Marker([throne_form_marker_lng_val, throne_form_marker_lat_val], {draggable:'true'});
                throne_form_marker_lat.val(throne_form_marker_lng_val);
                throne_form_marker_lng.val(throne_form_marker_lat_val);
                ms.on('dragend', function(event){
                    const marker = event.target;
                    const position = marker.getLatLng();
                    throne_form_marker_lat.val(position.lat);
                    throne_form_marker_lng.val(position.lng);
                });
                markersList.push(ms);
                markers.addLayer(ms);
                map.addLayer(markers);
                map.setView(new L.LatLng(throne_form_marker_lng_val, throne_form_marker_lat_val), 16);
                throne_localize.prop('disabled', false);
                mapLoad.hide();
            }, 2000);
        });
    }
});
