import { initMap } from "./maps";
import $ from "jquery";

$(document).ready( function () {
    // initMap(52.237049, 20.017532, 'map');
    $("#mapLink").on('click', function () {
       window.location.href = '/';
    });
});


